import { useColorMode } from "theme-ui";
import { DarkSwitchMode } from "./index";

export const DarkToggleMode: React.FC = () => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <DarkSwitchMode 
        onClick={() => {
            setColorMode(colorMode === 'default' ? 'dark' : 'default')
        }}
        dark = {colorMode === 'dark'}
    />
  );
};
