import { Button } from "theme-ui";

import { Decimal, UnipoolStakeChange } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

type UnipoolStakingActionProps = {
  change: UnipoolStakeChange<Decimal>;
};

export const UnipoolStakingManagerAction: React.FC<UnipoolStakingActionProps> = ({ change, children }) => {
  const { liquity } = useLiquity();

  const [sendTransaction] = useTransactionFunction(
    "unipool-stake",
    change.stakeUniTokens
      ? liquity.send.stakeUniTokens.bind(liquity.send, change.stakeUniTokens)
      : liquity.send.unstakeUniTokens.bind(liquity.send, change.unstakeUniTokens)
  );

  return <Button onClick={sendTransaction}>{children}</Button>;
};
