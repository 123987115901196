import { LiquityStoreUpdate, useLiquityReducer } from "@liquity/lib-react";

import { RedemptionViewAction, RedemptionViewContext } from "./RedemptionViewContext";

type RedemptionViewProviderAction = 
  | LiquityStoreUpdate
  | RedemptionViewAction

type RedemptionViewProviderState = {
  adjusting: boolean;
};

const init = (): RedemptionViewProviderState => ({
  adjusting: false
});

const reduce = (
  state: RedemptionViewProviderState,
  action: RedemptionViewProviderAction
): RedemptionViewProviderState => {
  // console.log(state);
  // console.log(action);

  switch (action.type) {
    case "startAdjusting":
      return { ...state, adjusting: true };

    case "cancelAdjusting":
      return { ...state, adjusting: false };

    case "updateStore": {
      return {
        ...state
      };
    }
  }

  return state;
};

export const RedemptionViewProvider: React.FC = ({ children }) => {
  const [{ adjusting }, dispatch] = useLiquityReducer(reduce, init);
  return (
    <RedemptionViewContext.Provider
      value={{
        view: adjusting ? "ADJUSTING" : "NONE",
        dispatch
      }}
    >
      {children}
    </RedemptionViewContext.Provider>
  );
};
