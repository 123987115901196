import * as React from 'react';
import {ReactComponent as Sun} from './sun.svg'
import {ReactComponent as Moon} from './moon.svg'
import './style.css'

type ToggleModeProps = {
	dark: boolean;
	onClick: () => void
};

export const DarkSwitchMode: React.FC<ToggleModeProps> = ({dark, onClick}) => {
  
  return (
    <>
    	<input type="checkbox" id="darkmode-toggle" onChange={onClick} checked={dark} />
		<label htmlFor="darkmode-toggle" id="darkmod-label">
			<Sun />
			<Moon />
		</label>
	</>
  )
}