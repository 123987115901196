
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import Chains from "../config/chainConfig";

function useSwitchChain() {
    const { library } = useWeb3React<Web3Provider>();
    return ( id: string) => {
        ;(async function () {
            if ( process.env.REACT_APP_DEMO_MODE === "true" || !library || !library.provider.request) return
            try {
                await library.provider?.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${Chains[id].chainId.toString(16)}` }],
                })
            } catch {
                await library.provider?.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${Chains[id].chainId.toString(16)}`,
                            chainName: Chains[id].chainName,
                            nativeCurrency: Chains[id].nativeCurrency,
                            rpcUrls: [Chains[id].chainRpcUrl],
                            blockExplorerUrls: Chains[id].chainBlockExplorerUrls,
                        },
                    ],
                })
            }
        })()
    }
}

export default useSwitchChain