import { Container } from "theme-ui";

import { Trove } from "../components/Trove/Trove";
import { Stability } from "../components/Stability/Stability";
import { SystemStats } from "../components/SystemStats";
import { PriceManager } from "../components/PriceManager";
import { InflationManager } from "../components/InflationManager";
import { BondsTable } from "../components/Bonds/BondsTable";
import { UnipoolStaking } from "../components/UnipoolStaking/UnipoolStaking";
import { LPTokenManager } from "../components/LPTokenManager";
import { Staking } from "../components/Staking/Staking";
import { Liquidation } from "../components/Liquidation/Liquidation";
import { Redemption } from "../components/Redemption/Redemption";

export const Dashboard: React.FC = () => (

  

  <Container variant="columns">
    <Container variant="left">
      <BondsTable />
      <Trove />
      <Stability />
      <Staking />
      <UnipoolStaking />
      <Liquidation />
      <Redemption />
    </Container>

    <Container variant="right">
      <SystemStats />
      <PriceManager />
      <LPTokenManager />
      <InflationManager />
    </Container>
  </Container>
);
