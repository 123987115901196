import React, { useState, useEffect } from "react";
import { Card, Box, Heading, Flex, Button, Label, Input } from "theme-ui";

import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../hooks/LiquityContext";

import { Icon } from "./Icon";
import { Transaction } from "./Transaction";
import { LP } from "../strings";

export const LPTokenManager: React.FC = () => {
  const {
    liquity: {
      send: liquity,
      connection: { _priceFeedIsTestnet: canMinLPToken }
    },
    account
  } = useLiquity();

  const [mintAmount, setMinAmount] = useState("0");

  if(!canMinLPToken) {
    return null;
  }

  return (
    <Card>
      <Heading>Testnet LP Token Mint</Heading>

      <Box sx={{ p: [2, 3] }}>
        <Flex sx={{ alignItems: "stretch" }}>
          <Label>{LP}</Label>

          <Input
            type={canMinLPToken ? "number" : "text"}
            step="any"
            value={mintAmount}
            onChange={e => setMinAmount(e.target.value)}
            disabled={!canMinLPToken}
          />

          {canMinLPToken && (
            <Flex sx={{ ml: 2, alignItems: "center" }}>
              <Transaction
                id="mint-lp-token"
                tooltip="Mint"
                tooltipPlacement="bottom"
                send={overrides => {
                  if (!mintAmount) {
                    throw new Error("Invalid token amount");
                  }
                  return liquity._mintUniToken(Decimal.from(mintAmount), account, overrides);
                }}
              >
                <Button variant="icon">
                  <Icon name="chart-line" size="lg" />
                </Button>
              </Transaction>
            </Flex>
          )}
        </Flex>
      </Box>
    </Card>
  );
};
