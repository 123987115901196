import { createContext, useContext } from "react";

export type RedemptionView = "ADJUSTING" | "NONE";

export type RedemptionViewAction = { type: "startAdjusting" | "cancelAdjusting" };

export type RedemptionViewContextType = {
  view: RedemptionView;

  // Dispatch an action that changes the Redemption panel's view.
  dispatch: (action: RedemptionViewAction) => void;
};

export const RedemptionViewContext = createContext<RedemptionViewContextType | null>(null);

export const useRedemptionView = (): RedemptionViewContextType => {
  const context = useContext(RedemptionViewContext);

  if (context === null) {
    throw new Error("You must add a <RedemptionViewProvider> into the React tree");
  }

  return context;
};
