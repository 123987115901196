import { Button } from "theme-ui";

import { Decimalish } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

type BtnApproveLPTokenProps = {
  allowance: Decimalish | undefined;
};

export const BtnApproveLPToken: React.FC<BtnApproveLPTokenProps> = ({ allowance, children }) => {
  const { liquity } = useLiquity();

  
  const [sendTransaction] = useTransactionFunction(
    "unipool-stake",
    liquity.send.approveUniTokens.bind(liquity.send, allowance)
  );
    
  if(!allowance) {
    return null;
  }
  
  return <Button onClick={sendTransaction}>{children}</Button>;
};
