import React, { useCallback, useState } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { LiquityStoreState } from "@liquity/lib-base";
import { useTroveView } from "./context/TroveViewContext";
import { useLiquitySelector } from "@liquity/lib-react";
import { useWeb3React } from "@web3-react/core";
import { ErrorDescription } from "../ErrorDescription";
import { COIN } from "../../strings";

const selectAfterPresale = ({ afterPresale, owner }: LiquityStoreState) => ({afterPresale, owner});

export const NoTrove: React.FC = props => {
  const { dispatchEvent } = useTroveView();
  const {account} = useWeb3React()
  const {afterPresale, owner} = useLiquitySelector(selectAfterPresale);
  const [canOpen, setCanOpen] = useState(afterPresale|| owner === account)
  
  const handleOpenTrove = useCallback(() => {
    if (afterPresale || owner === account) {
      setCanOpen(true)
      dispatchEvent("OPEN_TROVE_PRESSED");
    }
    else setCanOpen(false)
  }, [afterPresale, owner, dispatchEvent, setCanOpen]);

  return (
    <Card>
      <Heading>Trove</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`You haven't borrowed any ${COIN} yet.`}>
          You can borrow {COIN} by opening a Trove.
        </InfoMessage>

        {!canOpen && <ErrorDescription>Troves cannot be opened before the presale ends.</ErrorDescription>}
        <Flex variant="layout.actions">
          <Button onClick={handleOpenTrove}>Open Trove</Button>
        </Flex>
      </Box>
    </Card>
  );
};
