import { Card, Heading, Box, Flex, Button } from "theme-ui";

import { InfoMessage } from "../InfoMessage";
import { useLiquidationView } from "./context/LiquidationViewContext";

export const NoLiquidation: React.FC = () => {
  const { dispatch } = useLiquidationView();

  return (
    <Card>
      <Heading>Liquidation</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`Liquidation is expected to be carried out by bots.`} />

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>Start Liquidation</Button>
        </Flex>
      </Box>
    </Card>
  );
};
