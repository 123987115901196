import { useLiquidationView } from "./context/LiquidationViewContext";
import { LiquidationManager } from "./LiquidationManager";
import { RiskyTroves } from "../RiskyTroves";
import { NoLiquidation } from "./NoLiquidation";

export const Liquidation: React.FC = () => {
  const { view } = useLiquidationView();

  switch (view) {
    case "ADJUSTING":
      return (
        <>
          <LiquidationManager />
          <RiskyTroves pageSize={10} />
        </>
      ) 

    case "NONE":
      return <NoLiquidation />;
  }
};
