import { createContext, useContext } from "react";

export type LiquidationView = "ADJUSTING" | "NONE";

export type LiquidationViewAction = { type: "startAdjusting" | "cancelAdjusting" };

export type LiquidationViewContextType = {
  view: LiquidationView;

  // Dispatch an action that changes the Liquidation panel's view.
  dispatch: (action: LiquidationViewAction) => void;
};

export const LiquidationViewContext = createContext<LiquidationViewContextType | null>(null);

export const useLiquidationView = (): LiquidationViewContextType => {
  const context = useContext(LiquidationViewContext);

  if (context === null) {
    throw new Error("You must add a <LiquidationViewProvider> into the React tree");
  }

  return context;
};
