import { useUnipoolStakingView } from "./context/UnipoolStakingViewContext";
import { ReadOnlyUnipoolStake } from "./ReadOnlyUnipoolStake";
import { UnipoolStakingManager } from "./UnipoolStakingManager";
import { NoUnipoolStake } from "./NoUnipoolStake";

export const UnipoolStaking: React.FC = () => {
  const { view } = useUnipoolStakingView();

  switch (view) {
    case "ACTIVE":
      return <ReadOnlyUnipoolStake />;

    case "ADJUSTING":
      return <UnipoolStakingManager />;

    case "NONE":
      return <NoUnipoolStake />;
  }
};
