import { Button } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";

const selectUnipoolStake = ({ unipoolStake }: LiquityStoreState) => unipoolStake;

export const UnipoolStakingGainsAction: React.FC = () => {
  const { liquity } = useLiquity();
  const { lqtyGain } = useLiquitySelector(selectUnipoolStake);

  const [sendTransaction] = useTransactionFunction(
    "unipool-stake",
    liquity.send.withdrawLQTYRewardFromLiquidityMining.bind(liquity.send)
  );

  return (
    <Button onClick={sendTransaction} disabled={lqtyGain.isZero}>
      Claim gains
    </Button>
  );
};
