import { Container, Card, Box, Paragraph, Link } from "theme-ui";
import { InfoMessage } from "../components/InfoMessage";

type CommingSoonProps = {
  label: string,
  desc: string
} 

export const CommingSoonPage: React.FC<CommingSoonProps> = ({ label, desc }) => (
  <Container
    variant="main"
    sx={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    <Card>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={label}>
          <Paragraph>{desc}</Paragraph>
          <br />
          <Paragraph>
            Follow our <Link href="https://twitter.com/lendrnetwork" target="_blank">Twitter</Link> to stay up to date on all Lendr updates!
          </Paragraph>
        </InfoMessage>
        </Box>
    </Card>
  </Container>
);
