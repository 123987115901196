import { Card, Heading, Box, Flex, Button } from "theme-ui";

import { InfoMessage } from "../InfoMessage";
import { useRedemptionView } from "./context/RedemptionViewContext";

export const NoRedemption: React.FC = () => {
  const { dispatch } = useRedemptionView();

  return (
    <Card>
      <Heading>Redemption</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`Redemptions are expected to be carried out by bots when arbitrage opportunities emerge.`} />

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>Start Redemption</Button>
        </Flex>
      </Box>
    </Card>
  );
};
