import { useRedemptionView } from "./context/RedemptionViewContext";
import { RedemptionManager } from "./RedemptionManager";
import { NoRedemption } from "./NoRedemption";

export const Redemption: React.FC = () => {
  const { view } = useRedemptionView();

  switch (view) {
    case "ADJUSTING":
      return <RedemptionManager />;

    case "NONE":
      return <NoRedemption />;
  }
};
