import { LiquityStoreUpdate, useLiquityReducer } from "@liquity/lib-react";

import { LiquidationViewAction, LiquidationViewContext } from "./LiquidationViewContext";

type LiquidationViewProviderAction = 
  | LiquityStoreUpdate
  | LiquidationViewAction

type LiquidationViewProviderState = {
  adjusting: boolean;
};

const init = (): LiquidationViewProviderState => ({
  adjusting: false
});

const reduce = (
  state: LiquidationViewProviderState,
  action: LiquidationViewProviderAction
): LiquidationViewProviderState => {
  // console.log(state);
  // console.log(action);

  switch (action.type) {
    case "startAdjusting":
      return { ...state, adjusting: true };

    case "cancelAdjusting":
      return { ...state, adjusting: false };

    case "updateStore": {
      return {
        ...state
      };
    }
  }

  return state;
};

export const LiquidationViewProvider: React.FC = ({ children }) => {
  const [{ adjusting }, dispatch] = useLiquityReducer(reduce, init);
  return (
    <LiquidationViewContext.Provider
      value={{
        view: adjusting ? "ADJUSTING" : "NONE",
        dispatch
      }}
    >
      {children}
    </LiquidationViewContext.Provider>
  );
};
