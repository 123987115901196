import React, { useState } from "react";
import { Card, Box, Heading, Flex, Button, Label, Input } from "theme-ui";

import { useLiquity } from "../../hooks/LiquityContext";
import { useLiquidationView } from "./context/LiquidationViewContext";

import { Icon } from "../Icon";
import { Transaction } from "../Transaction";
import { InfoMessage } from "../InfoMessage";

export const LiquidationManager: React.FC = () => {
  const {
    liquity: { send: liquity }
  } = useLiquity();
  const { dispatch: dispatchLiquidationAction } = useLiquidationView();
  const [numberOfTrovesToLiquidate, setNumberOfTrovesToLiquidate] = useState("90");

  return (
    <Card>
      <Heading>Liquidate</Heading>

      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="Liquidation is expected to be carried out by bots." />
        <Flex sx={{ alignItems: "stretch" }}>
          <Label>Up to</Label>

          <Input
            type="number"
            min="1"
            step="1"
            value={numberOfTrovesToLiquidate}
            onChange={e => setNumberOfTrovesToLiquidate(e.target.value)}
          />

          <Label>Troves</Label>
        </Flex>
        <Flex variant="layout.actions" mt='3'>
        <Button
          variant="cancel"
          onClick={() => dispatchLiquidationAction({ type: "cancelAdjusting" })}
        >
          Cancel
        </Button>
          <Transaction
            id="batch-liquidate"
            tooltip="Liquidate"
            tooltipPlacement="bottom"
            send={overrides => {
              if (!numberOfTrovesToLiquidate) {
                throw new Error("Invalid number");
              }
              return liquity.liquidateUpTo(parseInt(numberOfTrovesToLiquidate, 10), overrides);
            }}
          >
            <Button>
              Confirm
            </Button>
          </Transaction>
        </Flex>
      </Box>
    </Card>
  );
};
