import { useEffect } from "react";

import { LiquityStoreState,  UnipoolStake } from "@liquity/lib-base";
import { LiquityStoreUpdate, useLiquityReducer } from "@liquity/lib-react";

import { useMyTransactionState } from "../../Transaction";

import { UnipoolStakingViewAction, UnipoolStakingViewContext } from "./UnipoolStakingViewContext";

type UnipoolStakingViewProviderAction =
  | LiquityStoreUpdate
  | UnipoolStakingViewAction
  | { type: "startChange" | "abortChange" };

type UnipoolStakingViewProviderState = {
  unipoolStake: UnipoolStake;
  changePending: boolean;
  adjusting: boolean;
};

const init = ({ unipoolStake }: LiquityStoreState): UnipoolStakingViewProviderState => ({
  unipoolStake,
  changePending: false,
  adjusting: false
});

const reduce = (
  state: UnipoolStakingViewProviderState,
  action: UnipoolStakingViewProviderAction
): UnipoolStakingViewProviderState => {
  // console.log(state);
  // console.log(action);

  switch (action.type) {
    case "startAdjusting":
      return { ...state, adjusting: true };

    case "cancelAdjusting":
      return { ...state, adjusting: false };

    case "startChange":
      return { ...state, changePending: true };

    case "abortChange":
      return { ...state, changePending: false };

    case "updateStore": {
      const {
        oldState: { unipoolStake: oldStake },
        stateChange: { unipoolStake: updatedStake }
      } = action;

      if (updatedStake) {
        const changeCommitted =
          !updatedStake.stakedLPToken.eq(oldStake.stakedLPToken) ||
          updatedStake.lqtyGain.lt(oldStake.lqtyGain);

        return {
          ...state,
          unipoolStake: updatedStake,
          adjusting: false,
          changePending: changeCommitted ? false : state.changePending
        };
      }
      return {
        ...state,
        changePending: false
      };
    }
  }

  return state;
};

export const UnipoolStakingViewProvider: React.FC = ({ children }) => {
  const stakingTransactionState = useMyTransactionState("unipool-stake");
  const [{ adjusting, changePending, unipoolStake }, dispatch] = useLiquityReducer(reduce, init);

  useEffect(() => {
    if (
      stakingTransactionState.type === "waitingForApproval" ||
      stakingTransactionState.type === "waitingForConfirmation"
    ) {
      dispatch({ type: "startChange" });
    } else if (
      stakingTransactionState.type === "failed" ||
      stakingTransactionState.type === "cancelled"
    ) {
      dispatch({ type: "abortChange" });
    }
  }, [stakingTransactionState.type, dispatch]);

  return (
    <UnipoolStakingViewContext.Provider
      value={{
        view: adjusting ? "ADJUSTING" : unipoolStake.isEmpty ? "NONE" : "ACTIVE",
        changePending,
        dispatch
      }}
    >
      {children}
    </UnipoolStakingViewContext.Provider>
  );
};
