import { Heading, Box, Card, Flex, Button } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT, LP } from "../../strings";

import { DisabledEditableRow, StaticRow } from "../Trove/Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { Icon } from "../Icon";

import { useUnipoolStakingView } from "./context/UnipoolStakingViewContext";
import { UnipoolStakingGainsAction } from "./UnipoolStakingGainsAction";

const select = ({ unipoolStake, totalStakedUniTokens }: LiquityStoreState) => ({
  unipoolStake,
  totalStakedUniTokens
});

export const ReadOnlyUnipoolStake: React.FC = () => {
  const { changePending, dispatch } = useUnipoolStakingView();
  const { unipoolStake, totalStakedUniTokens } = useLiquitySelector(select);

  const poolShare = unipoolStake.stakedLPToken.mulDiv(100, totalStakedUniTokens);

  return (
    <Card>
      <Heading>Liquidity Pool Token Staking</Heading>

      <Box sx={{ p: [2, 3] }}>
        <DisabledEditableRow
          label="Stake"
          inputId="stake-lp-token"
          amount={unipoolStake.stakedLPToken.prettify()}
          unit={LP}
        />

        <StaticRow
          label="Pool share"
          inputId="unipool-stake-share"
          amount={poolShare.prettify(4)}
          unit="%"
        />

        <StaticRow
          label={`${GT} gain`}
          inputId="unipool-stake-gain-lqty"
          amount={unipoolStake.lqtyGain.prettify(4)}
          color={unipoolStake.lqtyGain.nonZero && "success"}
          unit={GT}
        />

        <Flex variant="layout.actions">
          <Button variant="outline" onClick={() => dispatch({ type: "startAdjusting" })}>
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>

          <UnipoolStakingGainsAction />
        </Flex>
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
