import { createContext, useContext } from "react";

export type UnipoolStakingView = "ACTIVE" | "ADJUSTING" | "NONE";

export type UnipoolStakingViewAction = { type: "startAdjusting" | "cancelAdjusting" };

export type UnipoolStakingViewContextType = {
  view: UnipoolStakingView;

  // Indicates that a staking TX is pending.
  // The panel should be covered with a spinner overlay when this is true.
  changePending: boolean;

  // Dispatch an action that changes the Staking panel's view.
  dispatch: (action: UnipoolStakingViewAction) => void;
};

export const UnipoolStakingViewContext = createContext<UnipoolStakingViewContextType | null>(null);

export const useUnipoolStakingView = (): UnipoolStakingViewContextType => {
  const context = useContext(UnipoolStakingViewContext);

  if (context === null) {
    throw new Error("You must add a <UnipoolStakingViewProvider> into the React tree");
  }

  return context;
};
